/* Reset styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body styles */
body {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    background: linear-gradient(to bottom, #c4eeff,#ebfffc, #c7c4ff);
}

/* Header styles */
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 20px;
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 24px;
  font-weight: bold;
}

nav {
  display: flex;
  align-items: center;
}

nav ul {
  display: flex;
  list-style: none;
}

nav li {
  margin-right: 30px;
}

nav a {
  display: block;
  padding: 10px 20px;
  color: #333;
  text-decoration: none;
  transition: all 0.3s ease;
}

    nav a:hover {
        background-color: #364952;
        color: #fff;
    }

.menu-toggle {
    display: none;
    background-color: #364952;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

@media (max-width: 768px) {
  nav {
    display: none;
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    height: calc(100vh - 80px);
    background-color: #007bff;
    color: #fff;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9;
    transition: all 0.3s ease;
  }

  nav ul {
    display: block;
    margin-bottom: 30px;
  }

  nav li {
    margin: 0;
  }

  nav a {
    display: inline-block;
    margin-bottom: 10px;
  }

  .menu-toggle {
    display: block;
  }

  .show-menu {
    display: flex;
  }
}

/* Main content styles */
main {
    max-width: 800px;
    margin: 100px auto 0;
    padding: 40px;
    background-color: #e6eef2;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}

section {
  margin-bottom: 40px;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  margin-bottom: 10px;
}

/* Education section styles */
.education-item {
  display: flex;
  margin-bottom: 20px;
}

.education-item .education-info {
  flex: 1;
}

.education-item h3 {
  font-size: 20px;
  font-weight: bold;
}

.education-item p {
  margin-bottom: 5px;
}

    .education-item .education-date {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #364952;
        color: #fff;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        margin-right: 20px;
    }

.education-item .education-date span {
  font-size: 24px;
  font-weight: bold;
}

/* Skills section styles */
.skills-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

    .skills-list li {
        background-color: #364952;
        color: #fff;
        padding: 10px 20px;
        margin-right: 10px;
        margin-bottom: 10px;
        border-radius: 20px;
        font-size: 16px;
        font-weight: bold;
    }

/* Contact section styles */
.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#message {
    width: 100%; /* Change this value to your desired width */
    height: 300px; /* Change this value to your desired height */
}

.contact-form label {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

    .contact-form input,
    .contact-form textarea {
        width: 100%;
        height: 40px;
        
        border: 3px solid #333;
        border-radius: 5px;
        margin-bottom: 20px;
        font-size: 16px;
    }

.contact-form input {
  width: 100%;
}

    .contact-form button {
        background-color: #364952;
        color: #fff;
        border: none;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.3s ease;
    }

.contact-form button:hover {
  background-color: #fff;
  color: #007bff;
}

/* Footer styles */
footer {
  background-color: #333;
  color: #fff;
  padding: 6px;
  text-align: center;
}

.social-media {
  margin-bottom: 6px;
}

.social-media a {
  display: inline-block;
  margin-right: 20px;
}

.social-media i {
  font-size: 24px;
}
#about {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
}

    #about .image-container {
        flex: 1;
        margin-right: 20px;
    }

    #about .text-container {
        flex: 2;
    }

    #about img {
        max-width: 30%;
        height: auto;
        border-radius: 4%;
        border: 5px solid #333;
        margin-right: 20px;
    }
@media (max-width: 480px) {
    main {
        margin-top: 80px;
        padding: 20px;
    }

    .menu-toggle {
        display: none;
        background-color: #364952;
        color: #fff;
        border: none;
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;
    }

    .education-item .education-date {
        width: 80px;
        height: 80px;
        font-size: 12px;
    }

    .education-item h3 {
        font-size: 18px;
    }

    .education-item p {
        font-size: 14px;
    }

    .skills-list li {
        font-size: 14px;
        padding: 5px 10px;
    }

    .contact-form input,
    .contact-form textarea {
        font-size: 14px;
    }

    footer {
        padding: 20px;
    }

    .social-media i {
        font-size: 20px;
    }

   

}